import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../services/user.service";

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {

  initForm: FormGroup;
  mailForm: FormGroup;
  confirmedMail: boolean = false;
  pwShown: boolean = false;
  mailSended: boolean = false;
  isLoading: boolean = false;
  token: string = null;
  errorToken: string = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService:UserService,
    private formBuilder: FormBuilder
  ) {
    let params:any=this.activatedRoute.queryParams;
    this.confirmedMail=params.value.token!==undefined;
    if(this.confirmedMail) {
      this.isLoading=true;
      this.token = params.value.token;
      this.userService.verifToken(this.token).then((res)=>{
        this.isLoading=false;
      }, (err)=> {
        if(err.status===400){
          this.errorToken = err.error.message;
          this.confirmedMail=false;
          this.isLoading=false;
        }
      });
    }
  }

  ngOnInit() {
    this.mailForm = this.formBuilder.group({
      'email': ["", [Validators.required, Validators.email]]
    });
    this.initForm = this.formBuilder.group({
      'password': ["", [Validators.required]]
    });
  }

  switchpassword() {
    this.pwShown = !this.pwShown;
  }

  submitMail() {
    if(this.mailForm.valid) {
      this.userService.sendMailReset(this.mailForm.value).then((res)=> {
        this.router.navigate(["login"]);
      });
    }
  }

  submitPwd() {
    if(this.initForm.valid) {
      let obj=this.initForm.value;
      obj.token=this.token;
      this.userService.resetPassword(obj).then((res)=>{
        this.router.navigate(["login"]);
      })
    }
  }

}
