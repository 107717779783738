import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {AuthService} from "./auth.service";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private baseUrl: string = environment.apiUrl + '/user';
  private baseUrl2: string = environment.apiUrl + '/edit_user_password';

  constructor(
    private httpClient: HttpClient,
    private _authService: AuthService) { }

  public getUser() {
    return this.httpClient.get(this.baseUrl, this._authService.getHeaderRequest()).toPromise();
  }

  public edit_user_password(obj) {
    return this.httpClient.patch(this.baseUrl2, obj, this._authService.getHeaderRequest()).toPromise();
  }

  getFollowersOfUser() {
    return this.httpClient.get(environment.apiUrl+'/user_followed_artists', this._authService.getHeaderRequest()).toPromise();
  }

  sendMailReset(email) {
    return this.httpClient.post(environment.apiUrl+'/password/create', email).toPromise();
  }

  verifToken(token) {
    return this.httpClient.get(environment.apiUrl+'/password/find/'+token).toPromise();
  }

  resetPassword(obj) {
    return this.httpClient.post(environment.apiUrl+'/password/reset', obj).toPromise();
  }
}
