import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from "./services/auth.service";
import {Location} from '@angular/common';
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'cultures';
  public router: any;
  isDesktop: boolean = true;
  route: string;

  constructor(private _router: Router, private authService: AuthService, location: Location) {
    this.route = location.path();
    if (window.location.protocol === 'http:') {
      window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
    }
  }

  ngOnInit(): void {

  }

  checkIsLoggedin() {
    return this.authService.isLoggedIn();
  }

}
