import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { SignupComponent } from './signup/signup.component';
import { DownloadComponent } from './download/download.component';
import { AuthGuard } from './guards/auth.guard';
import {RandomGuard} from "./guards/random.guard";
import {ResetComponent} from "./reset/reset.component";
import {FaqComponent} from "./faq/faq.component";
import {ConditionComponent} from "./condition/condition.component";
import {MentionComponent} from "./mention/mention.component";
import {PrivacyComponent} from "./privacy/privacy.component";

const routes: Routes = [
  {
    path: "home",
    component: HomeComponent
  },
  {
    path: "reset",
    component: ResetComponent
  },
  {
    path: "faq",
    component: FaqComponent
  },
  {
    path: "privacy",
    component: PrivacyComponent
  },
  {
    path: "condition-generales",
    component: ConditionComponent
  },
  {
    path: "mentions-legales",
    component: MentionComponent
  },
  // {
  //   path: "login",
  //   component: LoginComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: "signup",
  //   component: SignupComponent,
  //   canActivate: [AuthGuard]
  // }
  // ,
  {
    path: "download",
    component: DownloadComponent
  },
  {
    path: 'play',
    loadChildren: './Client/Modules/explorer/explorer.module#ExplorerModule',
    canActivate: [RandomGuard],
    canLoad: [RandomGuard]
  },
  {
    path: "**",
    redirectTo : "home"
  },
  {
    path: "",
    redirectTo : "home",
    pathMatch: "full"
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
