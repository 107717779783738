import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HomeService} from "../services/home.service";
import {NotificationsService} from "angular2-notifications";


@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit {

  valueDate: Date = new Date();
  submitted: boolean = false;

  subscribeForm: FormGroup;

  constructor(private homeService: HomeService,
              private _notifications: NotificationsService,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    // let wind: any = window;
    // var md = new wind.MobileDetect(window.navigator.userAgent);
    // alert(md.mobile());
    // window.location.href = "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.myapp";
    this.subscribeForm = this.formBuilder.group({
      'email': ['', [Validators.required, Validators.email]]
    });
  }

  subscribe() {
    this.submitted=true;
    let obj={
      timeOut: 5000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true,
      animate: 'fromRight'
    };
    if(this.subscribeForm.valid) {
      this.homeService.subscribe(this.subscribeForm.value).then((res)=>{
        this.submitted=false;
        this.subscribeForm.controls.email.setValue("");
        this._notifications.success("Succès", "Vous êtes désormais inscrit.", obj);
      }, (err)=>{
        let obj={
          timeOut: 5000,
          showProgressBar: true,
          pauseOnHover: true,
          clickToClose: true,
          animate: 'fromRight'
        };
        this._notifications.error("Attention", err.email, obj);
      });
    }
  }

  openPlayStore() {
    window.location.href = "https://apps.apple.com/us/app/culture-contre-culture/id1472521496?ls=1";
  }

}
