import { Component, OnInit } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { Router } from "@angular/router";
import { of } from "rxjs/index";
import { catchError, mapTo, tap } from "rxjs/operators";
import { NotificationsService } from "angular2-notifications";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  pwShown: boolean = false;
  isLoading: boolean = false;

  constructor(
    private authService: AuthService,
    private _notifications: NotificationsService,
    private router: Router
  ) {}

  ngOnInit() {}

  login(f: any) {
    this.isLoading = true;
    this.authService
      .login(f.value)
      .pipe(
        tap((tokens) => this.authService.doLoginUser(f.value.email, tokens)),
        mapTo(true),
        catchError((error) => {
          let obj = {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true,
            animate: "fromRight",
          };
          this._notifications.error(
            "Attention",
            "E-mail ou mot de passe invalide.",
            obj
          );
          this.isLoading = false;
          f.controls.password.setValue("");
          //alert(error.error);
          return of(false);
        })
      )
      .subscribe(
        (success) => {
          if (success) {
            let wind: any = window;
            var OneSignal = wind.OneSignal || [];
            OneSignal.push(function () {
              OneSignal.init({
                appId: "d46ecd97-e65c-4776-9e80-bbc1b6ffeb46",
                autoResubscribe: true,
                notifyButton: {
                  enable: false,
                },
              });
              // OneSignal.showNativePrompt();
              OneSignal.setEmail(f.value.email);
            });
            setTimeout(() => {
              this.router.navigate(["/play"]);
              this.isLoading = false;
              localStorage.setItem("email", f.value.email);
            }, 200);
          }
        },
        (err) => {
          this.isLoading = false;
        },
        () => {
          //complete
        }
      );
  }

  switchpassword() {
    this.pwShown = !this.pwShown;
  }
}
