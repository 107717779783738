import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SongService {

  private baseUrl: string = environment.apiUrl + '/song';
  private baseUrlLiked: string = environment.apiUrl + '/liked_songs';
  private baseUrlAlbumLiked: string = environment.apiUrl + '/liked_albums';

  constructor(
    private httpClient: HttpClient,
    private _authService: AuthService) {
  }

  public getByAlbum(idAlbum) {
    return this.httpClient.get(this.baseUrl+'s/'+idAlbum, this._authService.getHeaderRequest()).toPromise();
  }

  public getLikeSongs() {
    return this.httpClient.get(this.baseUrlLiked, this._authService.getHeaderRequest()).toPromise();
  }

  public getLikeAlbumSongs() {
    return this.httpClient.get(this.baseUrlAlbumLiked, this._authService.getHeaderRequest()).toPromise();
  }

  public getLikedSongsByAlbum(id) {
    return this.httpClient.get(environment.apiUrl+'/liked_songs_no_albums?album_id='+id, this._authService.getHeaderRequest()).toPromise();
  }

  public likeSong(id) {
    return this.httpClient.get(environment.apiUrl+'/like_song/'+id, this._authService.getHeaderRequest()).toPromise();
  }

  public dislike_song(id) {
    return this.httpClient.get(environment.apiUrl+'/dislike_song/'+id, this._authService.getHeaderRequest()).toPromise();
  }

  public startSong(id) {
    return this.httpClient.get(environment.apiUrl+'/start_song/'+id, this._authService.getHeaderRequest()).toPromise();
  }
}
