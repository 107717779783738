import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {NotificationsService} from "angular2-notifications";
import {catchError, mapTo, tap} from "rxjs/operators";
import {of} from "rxjs/index";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  pwShown: boolean = false;
  isLoading: boolean = false;
  submitted: boolean = false;
  rep: string;
  signForm: FormGroup;

  constructor(
    private authService: AuthService,
    private _notifications: NotificationsService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit() {
    this.signForm = this.formBuilder.group({
      'first_name': new FormControl("", Validators.required),
      'last_name': new FormControl("", Validators.required),
      'birth_day': new FormControl("", Validators.required),
      'email': new FormControl("", Validators.required),
      'password': new FormControl("", Validators.required),
      'g-recaptcha-response': new FormControl(null, Validators.required),
    });
  }

  resolved(captchaResponse: string) {
    this.rep = captchaResponse;
  }

  public submit = () => {
    let ref = this.signForm;
    this.submitted = true;
    if (ref.valid && ref.value.password.length > 3 && this.isValidDate(ref.value.birth_day)) {
      this.isLoading = true;
      ref["g-recaptcha-response"] = this.rep;
      this.authService.registerAndLogin(ref.value).pipe(
        tap(tokens => this.authService.doLoginUser(ref.value.email, tokens)),
        mapTo(true),
        catchError(error => {
          let obj = {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true,
            animate: 'fromRight'
          };
          if (error.error.email) {
            this._notifications.error("Attention", error.error.email[0], obj);
          }else{
            this._notifications.error("Attention", "Veuillez réessayer ultérieurement.", obj);
          }

          this.isLoading = false;
          //alert(error.error);
          return of(false);
        })).subscribe(
        success => {
          if (success) {
            let wind: any = window;
            var OneSignal = wind.OneSignal || [];
            OneSignal.push(function () {
              OneSignal.init({
                appId: "d46ecd97-e65c-4776-9e80-bbc1b6ffeb46",
                autoResubscribe: true,
                notifyButton: {
                  enable: false,
                },
              });
              // OneSignal.showNativePrompt();
              OneSignal.setEmail(ref.value.email);
            });
            this.router.navigate(['/play']);
            this.isLoading = false;
          }
        },
        (err) => {
          this.isLoading = false;

        },
        () => {
          //complete
        });
    }

    // .subscribe(
    // success => {
    //   if (success) {
    //
    //     this.router.navigate(['/play']);
    //   }
    // },
    // (err) => {
    //   console.log('err');
    //
    // },
    // () => {
    //   //complete
    // });

  }

  isValidDate(val) {

    let tab = val.split('-');

    var day = +tab[0],
      month = +tab[1],
      year = +tab[2];

    var date = new Date();
    let thisYear = date.getFullYear();
    date.setFullYear(year, month - 1, day);

    let test = false;
    if ((date.getFullYear() == year && year >= 1910 && year <= thisYear) && (date.getMonth() + 1 == month) && (date.getDate() == day))
      test = true;
    return test;
  }

  switchpassword() {
    this.pwShown = !this.pwShown;
  }

}
