import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {Track} from './player/Track.interface';

@Injectable({ providedIn: 'root' })
export class TrackSubjectService {
  private subjectTrack = new Subject<any>();
  private subjectCurrentTrackID = new Subject<number>();
  private subjectPlayList = new Subject<any>();

  sendMessage(message: any) {
    this.subjectTrack.next(message);
  }

  getMessage(): Observable<any> {
    return this.subjectTrack.asObservable();
  }

  getSongSelected() {
    return this.subjectCurrentTrackID.asObservable();
  }

  sendSongSelected(msg) {
    this.subjectCurrentTrackID.next(msg);
  }

  getPlayList() {
    return this.subjectPlayList.asObservable();
  }

  sendPlayList(list) {
    this.subjectPlayList.next(list);
  }
}
