import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {LoginComponent} from './login/login.component';
import {SignupComponent} from './signup/signup.component';
import {DownloadComponent} from './download/download.component';


import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthService} from './services/auth.service';
import {FooterNavBarComponent} from './footer-nav-bar/footer-nav-bar.component';
import {NgxNavigationWithDataComponent} from "ngx-navigation-with-data";
import {PlayerComponent} from "./webplayer/player/player.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {LoadingModule} from "./loading/loading.module";
import {NotificationsService, SimpleNotificationsModule} from "angular2-notifications";
import {CookieLawModule} from "angular2-cookie-law";
import {ResetComponent} from './reset/reset.component';
import {FaqComponent} from './faq/faq.component';
import {ConditionComponent} from './condition/condition.component';
import {MentionComponent} from './mention/mention.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from "ng-pick-datetime";
import {MAT_DATE_LOCALE, MatDatepickerModule, MatFormFieldModule, MatNativeDateModule} from "@angular/material";
import {RecaptchaFormsModule, RecaptchaModule} from "ng-recaptcha";
import {NgxMaskModule} from "ngx-mask";
import { PrivacyComponent } from './privacy/privacy.component';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    PlayerComponent,
    LoginComponent,
    SignupComponent,
    DownloadComponent,
    FooterNavBarComponent,
    ResetComponent,
    FaqComponent,
    ConditionComponent,
    MentionComponent,
    PrivacyComponent
  ],
  imports: [
    BrowserModule,
    LoadingModule,
    MatDatepickerModule,
    CookieLawModule,
    NgxMaskModule.forRoot(),
    MatFormFieldModule,
    MatNativeDateModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    SimpleNotificationsModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [AuthService,{provide: MAT_DATE_LOCALE, useValue: 'fr'},
    NotificationsService, NgxNavigationWithDataComponent],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
