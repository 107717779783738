import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AuthService} from "./auth.service";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private baseUrl: string = environment.apiUrl + '/home_full';

  constructor(
    private http: HttpClient,
    private _authService: AuthService) {
  }

  getTodayArtist() {
    return this.http.get(this.baseUrl, this._authService.getHeaderRequest()).toPromise();
  }

  subscribe(obj) {
    return this.http.post(environment.apiUrl + '/news/subscribe', obj).toPromise();
  }
  getHome() {
    return this.http.get(environment.apiUrl + '/get_home').toPromise();
  }
}
