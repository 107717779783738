import { Component, OnInit } from '@angular/core';
import {HomeService} from "../services/home.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NotificationsService} from "angular2-notifications";
import {AuthService} from "../services/auth.service";
import {DomSanitizer} from "@angular/platform-browser";
import { Router, ActivatedRoute, ParamMap, Params } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  valueDate: Date = new Date();
  submitted: boolean = false;
  homeContent: any;
  isLogin: boolean = true;

  subscribeForm: FormGroup;
  queryParams: Params;

  constructor(private homeService: HomeService,
              private _notifications: NotificationsService,
              private authService: AuthService,
              private formBuilder: FormBuilder,
              private sanitizer: DomSanitizer,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.subscribeForm = this.formBuilder.group({
      'email': ['', [Validators.required, Validators.email]]
    });
    this.homeService.getHome().then((res)=>{
      this.homeContent=res;
      this.homeContent.homePageHtml = this.sanitizer.bypassSecurityTrustHtml(this.homeContent.homePageHtml);
    })
    this.route.queryParams.subscribe(params => {
      this.queryParams = params;
    });
  }

  ngAfterViewInit() {
    let obj = {
      timeOut: 5000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true,
      animate: 'fromRight'
    };

    if (this.queryParams.hasOwnProperty('success-payment')) {
      this._notifications.success("Succès", "Merci pour votre commande !", obj);
    } else if (this.queryParams.hasOwnProperty('canceled-payment')) {
      this._notifications.error("Attention", "Paiement annulé.", obj);
    }
  }

  scrollBar() {
    document.getElementById('firstSection').scrollIntoView();
  }

  subscribe() {
    this.submitted=true;
    let obj={
      timeOut: 5000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true,
      animate: 'fromRight'
    };
    if(this.subscribeForm.valid) {
      this.homeService.subscribe(this.subscribeForm.value).then((res)=>{
        this.submitted=false;
        this.subscribeForm.controls.email.setValue("");
        this._notifications.success("Succès", "Vous êtes désormais inscrit.", obj);
      }, (err)=>{
        let obj={
          timeOut: 5000,
          showProgressBar: true,
          pauseOnHover: true,
          clickToClose: true,
          animate: 'fromRight'
        };
        this._notifications.error("Attention", err.email, obj);
      });
    }
  }

  checkIsLoggedin() {
    return this.authService.isLoggedIn();
  }

}
