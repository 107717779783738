import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Track} from './Track.interface';
import {TrackSubjectService} from '../TrackSubject.service';
import {Subscription} from 'rxjs';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {Song} from '../../models/Song';
import {Album} from '../../models/Album';
import {SongService} from "../../services/song.service";

declare var $: any
declare var mediaelementplayer: any

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css']
})
export class PlayerComponent implements OnInit {

  album: Album;
  listTracks: Song[] = [];
  indexTrack: number = -1;
  trackPlayed: boolean = false;
  public track: any;
  private subscriptionMsg: Subscription;
  private subscriptionList: Subscription;
  public albumcover: string;
  public songName: string;
  public artistName: string;
  setVolume: string = 'hidden';
  volume: number = 10;
  currentDuration: number = 0;
  isPlaying: boolean = false;
  isUnMute: boolean = false;
  liked: boolean = false;
  currentDurationTime: string = '00:00';
  music_duration: string = '00:00';
  isRepeat: boolean = false;
  isShownPlaylist: boolean = false;
  albumName: string = '';

  constructor(
    private _trackService: TrackSubjectService,
    private _songService: SongService
  ) {
    this.subscriptionMsg = this._trackService.getMessage().subscribe((res) => {
      let item = res.song;
      let play = res.play;
      let ind = this.listTracks.length;
      let test = this.findTrack(item.id);
      if (test === -1) {
        this.listTracks.push(item);
      } else {
        ind = test;
      }
      if (play) {
        this.indexTrack = ind;
        this.playTrack(ind);
      }
      // if (res === null || res.length === 0) {
      //   // this.albumcover = './assets/music/material-cd.jpg';
      //   // this.artistName = 'Artiste';
      //   // this.songName = 'Chanson';
      //   // this.liked = false;
      //   // this.loadTrack(null);
      //   // this.listTracks = [];
      //   // this.music_duration='00:00';
      //   // this.albumName='';
      //   this.playPauseAudio();
      // } else {
      //   this.currentDurationTime = '00:00';
      //   this.indexTrack = 0;
      //   this.album = res;
      //   this.listTracks = this.album.songs;
      //   this.track = this.listTracks[0];
      //   this.loadTrack(this.track.music_url);
      //   this.albumcover = this.album.image_url;
      //   this.albumName = this.album.name;
      //   this.artistName = this.track.album_name;
      //   this.music_duration = this.track.music_duration.substring(3);
      //   this.liked = this.track.liked;
      //   this.songName = this.track.name;
      // }
    });
    this.subscriptionList = this._trackService.getPlayList().subscribe((res) => {
      this.listTracks = res;
      this.indexTrack = 0;
      this.playTrack(0);
    });

  }

  findTrack(id) {
    let test = -1;
    for (let i = 0; i < this.listTracks.length; i++) {
      if (this.listTracks[i].id === id) {
        test = i;
        break;
      }
    }
    return test;
  }

  ngOnInit() {

    let that = this;

    this.albumcover = './assets/music/material-cd.jpg';
    this.artistName = 'Artiste';
    this.songName = '';//Chanson
    let player = <HTMLAudioElement>document.getElementById('playlist-audio');

    let duration; // Duration of audio clip
    let btnPlayPause: any = document.getElementById('btnPlayPause');
    let btnMute: any = document.getElementById('btnMute');
    let progressBar: any = document.getElementById('progressBar');
    let volumeBar: any = document.getElementById('volume-bar');
    let source: any = document.getElementById('audioSource');

    volumeBar.addEventListener('change', (evt: any) => {
      player.volume = parseInt(evt.target.value) / 10;
      that.isUnMute = parseInt(evt.target.value) === 0;
    });

    player.addEventListener('timeupdate', () => {
      let secondes = parseInt(player.currentTime + '');
      if (secondes > 30 && !this.trackPlayed) {
        this.setPlayed(that.track.id);
        this.trackPlayed = true;
      }

      let hours = Math.floor(secondes / 3600);
      secondes %= 3600;
      let minutes = Math.floor(secondes / 60);
      let seconds = secondes % 60;
      let m = minutes + '', s = seconds + '';
      if (minutes < 10) {
        m = '0' + minutes;
      }
      if (seconds < 10) {
        s = '0' + seconds;
      }
      that.currentDurationTime = m + ':' + s;

      // Work out how much of the media has played via the duration and currentTime parameters
      const percentage = Math.floor((100 / player.duration) * player.currentTime);
      // Update the progress bar's value
      that.currentDuration = (player.currentTime / player.duration) * 100;
      document.getElementById('progressBar').setAttribute('value', this.currentDuration+'');
      that.loop();
    }, false);

    player.addEventListener('play', function () {
      // Change the button to be a pause button
      that.isPlaying = true;
    }, false);
    player.addEventListener('loadeddata', function () {
    }, false);

    player.addEventListener('pause', function () {
      // Change the button to be a play button
      that.isPlaying = false;
    }, false);

    // player.addEventListener('volumechange', function(e) {
    //   // Update the button to be mute/unmute
    //   if (player.muted)
    //     that.isUnMute=false;
    //   else
    //     that.isUnMute=true;
    // }, false);

    player.addEventListener('ended', () => {
      player.pause();
      that.nextTrack();
      let secondes = parseInt(player.currentTime + '');
      let hours = Math.floor(secondes / 3600);
      secondes %= 3600;
      let minutes = Math.floor(secondes / 60);
      let seconds = secondes % 60;
      let m = minutes + '', s = seconds + '';
      if (minutes < 10) {
        m = '0' + minutes;
      }
      if (seconds < 10) {
        s = '0' + seconds;
      }
      that.currentDurationTime = m + ':' + s;
    }, false);

    progressBar.addEventListener('click', (e: any) => {
      if (player.src) {
        let percent = e.offsetX / progressBar.offsetWidth;
        player.currentTime = percent * player.duration;
        this.currentDuration = Math.floor(percent / 100);
        document.getElementById('progressBar').setAttribute('value', this.currentDuration+'');
        this.currentDurationTime = '00:00';
      }
    });
  }

  seek(player, el, e) {
    // if (player.src) {
    //   let percent = e.offsetX / el.offsetWidth;
    //   player.currentTime = percent * player.duration;
    //   e.target.value = Math.floor(percent / 100);
    // }
  }

  playPauseAudio() {
    let player = <HTMLAudioElement>document.getElementById('playlist-audio');
    let btnPlayPause: any = document.getElementById('btnPlayPause');
    if (player.src) {
      if (player.paused || player.ended) {
        // Change the button to a pause button
        this.isPlaying = true;
        player.play();
      } else {
        // Change the button to a play button
        this.isPlaying = false;
        player.pause();
      }
    }
  }

  loop() {
    // let player = <HTMLAudioElement>document.getElementById('playlist-audio');
    // let buffered = player.buffered;
    // let loaded;
    // let played;
    //
    // if (buffered.length) {
    //   loaded = 100 * buffered.end(0) / player.duration;
    //   played = 100 * player.currentTime / player.duration;
    //   console.log("loaded", loaded.toFixed(2));
    //   console.log("played", played.toFixed(2));
    // }

  }

  loadTrack(audioSource: string) {
    this.trackPlayed = false;
    this.currentDuration = 0;
    this.isShownPlaylist = false;
    let player = <HTMLAudioElement>document.getElementById('playlist-audio');
    if (audioSource !== null) {
      player.src = audioSource;
      player.setAttribute('protect', this.track.id + '');
      player.play();
      this.currentDurationTime = '00:00';
      this.isPlaying = true;
    } else {
      player.src = undefined;
      player.pause();
      this.isPlaying = false;
    }
  }

  showTracks() {
    this.isShownPlaylist = !this.isShownPlaylist;
  }

  setFavorite(item) {
    if (item !== undefined) {
      if (item.liked) {
        item.liked = false;
        this.liked = false;
        this._songService.dislike_song(item.id).then((res) => {
          item.liked = false;
          this.liked = false;
          for (let i = 0; i < this.listTracks.length; i++) {
            if (this.listTracks[i].id === item.id) {
              this.listTracks[i].liked = false;
            }
          }
        });
      } else {
        item.liked = true;
        this.liked = true;
        this._songService.likeSong(item.id).then((res) => {
          item.liked = true;
          this.liked = true;
          for (let i = 0; i < this.listTracks.length; i++) {
            if (this.listTracks[i].id === item.id) {
              this.listTracks[i].liked = true;
            }
          }
        });
      }
    }
  }

  nextTrack() {
    if (this.indexTrack + 1 < this.listTracks.length) {
      this.indexTrack++;
      this.playTrack(this.indexTrack);
    } else if (this.isRepeat) {
      this.indexTrack = 0;
      this.playTrack(this.indexTrack);
    }
  }

  previousTrack() {
    if (this.indexTrack - 1 >= 0) {
      this.indexTrack--;
      this.playTrack(this.indexTrack);
    }
  }

  ajustVolume() {
    if (this.setVolume === 'hidden') {
      this.setVolume = 'visible';
    } else {
      this.setVolume = 'hidden';
    }
  }

  boucleTracks() {
    this.isRepeat = !this.isRepeat;
  }

  randomTrack() {

  }

  playTrack(i) {
    if (this.track!==undefined && this.track.id === this.listTracks[i].id) {
      this.playPauseAudio();
    } else {
      this.indexTrack = i;
      this.track = this.listTracks[this.indexTrack];
      this.sendSongSelected(this.track.id);
      this.currentDurationTime = '00:00';
      this.music_duration = this.track.music_duration.substring(3);
      this.loadTrack(this.track.music_url);
      this.albumcover = this.track.image_url;
      this.artistName = this.track.artist_name;
      this.liked = this.track.liked;
      this.songName = this.track.name;
    }

  }

  sendSongSelected(id) {
    this._trackService.sendSongSelected(id);
  }

  setPlayed(i) {
    this._songService.startSong(i);
  }
}
